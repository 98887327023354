import React from 'react'
import { Puff } from 'react-loader-spinner'

const LoaderThreeDot = () => {
  return (
    <>
    <Puff
  visible={true}
  height="80"
  width="80"
  color="#F3F4F7"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh' 
  }}  /></>
  )
}

export default LoaderThreeDot