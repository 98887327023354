import Navbar1 from 'components/navbars/Navbar1'
import React from 'react'
import { Outlet } from 'react-router-dom'

const BaseWrapper = () => {
  return (
    <div>
        <Navbar1
          navClass="navbar-light zindex-10"
          isSticky={true}
          fixedWidth
          hideSearch
          buttonClass="btn-primary btn-sm"
        />
        <Outlet />
    </div>
  )
}

export default BaseWrapper